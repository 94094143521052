<template>
    <div class="card card-info">
        <div class="card-header">
            <h3 class="card-title">Artículos</h3>
        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Identificador</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="articulos in pedido.articulos" :key="articulos">
                        <td>{{articulos.articulo.codigo}}</td>
                        <td>{{articulos.articulo.descripcion}}</td>
                        <td>{{articulos.cantidad}}</td>
                        <td>{{articulos.identificador}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
//import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'pedido', 'id_pedido'],
    data() {
        return {
            
        }
    }, 
    watch: {
        
    },
    methods: {
        
    },
    mounted() {

    },

}
</script>