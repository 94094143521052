<template>
    <div class="card card-warning">
        <div class="card-header">
            <h3 class="card-title">Historial</h3>
        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Artículo</th>
                        <th>Motivo</th>
                        <th>Cantidad</th>
                        <th>Usuario</th>
                        <th>Identificador</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</template>