<template>
    <div class="row">
        <div class="col-md-4">            
            <pedidodatos :id="id" :id_pedido="id_pedido" :pedido="pedido" />
        </div>
        <div class="col-md-8">   
            <pedidoarticulos :id="id" :id_pedido="id_pedido" :pedido="pedido" />
            <pedidohistorial :id="id" :id_pedido="id_pedido" :pedido="pedido"/>
        </div>

    </div>
</template>
<script>
import pedidodatos from './pedido/PedidoDatos.vue'
import pedidoarticulos from './pedido/PedidoArticulos.vue'
import pedidohistorial from './pedido/PedidoHistorial.vue'
import { PwgsApi } from '../../../services/PwgsApi'
export default {
    props: ['id','id_pedido'],
    components: {
        'pedidodatos': pedidodatos,
        pedidoarticulos,
        pedidohistorial
    },
    data() {
        return {
            pedido: [],      
        }
    },
    methods: {
        async obtenerPedido() {
            const api = new PwgsApi;
            this.pedido = [];
            this.pedido = await api.get('compras-pwgs/' + this.$props.id_pedido);
        },
    },
    watch:
    {
        id(){
            this.obtenerPedido();
        },   
    },
    mounted() { 
        this.obtenerPedido();
    },
}
</script>
